a {
  text-decoration: underline;
  text-decoration-color: rgba(25, 118, 210, 0.4);
  color: #1976d2;
}

a:hover {
  text-decoration-color: inherit;
}


#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}