.text-link {
    color: white;
    text-decoration: inherit;
}

.dropdown-link {
    color: rgba(0, 0, 0, 0.87);
    text-decoration: inherit;
}

.title {
    color: white !important;
    text-decoration-color: white !important;
    text-decoration: inherit;
}

#menu-icon {
    color: white;
}